import { DISPATCHER_PATHS, FLIGHT_PLAN_CONFIG_PATHS, DATA_CONFIGURATION_PATHS, CLOSE_FLIGHT_PATHS, FLIGHT_POLICY_PATHS, SIM_PATHS, UO_PATHS, Internal_Support_PATHS } from "./path";

/*
    found from db collection 'admin_user_groups'

    userGroupID: 'EFF.ADMIN.ALL', 'EFF.Admin-Super.ALL' => role: 'isAdmin'
    userGroupID: 'CX.Dispatch.ALL' => role: 'isCXDispatch'
    userGroupID: 'CX.Dispatch.READ' => role: 'isCXDispatch'
    userGroupID: 'CX.Sim.ALL' => role: 'isSimUser'
    userGroupID: 'UO.Dispatch.READ' => role: 'isUODispatch'
    userGroupID: 'CX.Audit.READ' => role: 'isCXAudit'
    userGroupID: 'EFF.Support.ALL' => role: 'isSupport'

*/

enum AccessType {
    VIEW = 'view',
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete',
}

// once add new role, need to add related roleAccessList item to home page
enum UserGroupIDType {
    SUPER_ADMIN = 'EFF.Admin-Super.ALL',
    ADMIN = 'EFF.ADMIN.ALL',
    CX_DISPATCH = 'CX.Dispatch.ALL',
    UO_DISPATCH_READ = 'UO.Dispatch.READ',
    CX_DISPATCH_READ = 'CX.Dispatch.READ',
    CX_SIM = 'CX.SIM.ALL',
    CX_AUDIT_READ = 'CX.Audit.READ',
    SUPPORT = 'EFF.Support.ALL',
    SUPER_VISOR = 'EFF.Supervisor.ALL',
}

interface RoleAccess {
    userGroupID: string; // userGroupID 'EFF.ADMIN.ALL'
    access: AccessType[];
}

interface RoleAccessMapping {
    routes: string[]; // Route path to identify the page (section/subSection)
    roleAccessList: RoleAccess[];
}

const ROLE_ACCESS_MAPPING: RoleAccessMapping[] = [
    {    // home page , once add new role, need to add related roleAccessList here
        routes: ['/'],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH_READ,
                access: [AccessType.VIEW],
            },
            {
                userGroupID: UserGroupIDType.UO_DISPATCH_READ,
                access: [AccessType.VIEW],
            },
            {
                userGroupID: UserGroupIDType.CX_SIM,
                access: [AccessType.VIEW],
            },
            {
                userGroupID: UserGroupIDType.CX_AUDIT_READ,
                access: [AccessType.VIEW],
            },
            {
                userGroupID: UserGroupIDType.SUPPORT,
                access: [AccessType.VIEW],
            },
            {
                userGroupID: UserGroupIDType.SUPER_VISOR,
                access: [AccessType.VIEW],
            },
        ],
    }, {   // Monitoring - Dashboard (Flight Plan)
        routes: [DISPATCHER_PATHS.flightPlan, DISPATCHER_PATHS.flightDetail],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.UO_DISPATCH_READ,
                access: [AccessType.VIEW],
            },
            {
                userGroupID: UserGroupIDType.SUPER_VISOR,
                access: [AccessType.VIEW, AccessType.UPDATE],
            },
        ],
    }, {
        routes: [DISPATCHER_PATHS.status],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
        ],
    }, {    // Flight Plan Configuration - SNN
        routes: [FLIGHT_PLAN_CONFIG_PATHS.snn],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH_READ,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
        ],
    }, {    // Not applicable, as page will be removed
        routes: [FLIGHT_POLICY_PATHS.fuelPolicy],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
        ],
    }, {    // Not applicable, as page will be removed
        routes: [FLIGHT_POLICY_PATHS.noFlyZonesPolicy],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
        ],
    }, {    // Not applicable, as page will be removed
        routes: [FLIGHT_POLICY_PATHS.tankeringPolicy],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
        ],
    }, {    // Not applicable, as page will be removed
        routes: [FLIGHT_POLICY_PATHS.volcanicAshesPolicy],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
        ],
    }, {    // Flight Plan Configuration - Appendix Rules
        routes: [FLIGHT_PLAN_CONFIG_PATHS.appendix],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH_READ,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
        ],
    }, {    // Flight Plan Configuration - Aircraft Information
        routes: [FLIGHT_PLAN_CONFIG_PATHS.aircraft],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH_READ,
                access: [AccessType.VIEW],
            },
        ],
    }, {    // Flight Plan Configuration - Port Page Notam
        routes: [FLIGHT_PLAN_CONFIG_PATHS.portPageNotam],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH_READ,
                access: [AccessType.VIEW],
            },
        ],
    }, {    // Flight Plan Configuration - Defects
        routes: [FLIGHT_PLAN_CONFIG_PATHS.defects],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH_READ,
                access: [AccessType.VIEW, AccessType.UPDATE],
            },
        ],
    }, {    // Flight Plan Configuration - NSU/CO NOTAM
        routes: [FLIGHT_PLAN_CONFIG_PATHS.nsuNotam],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH_READ,
                access: [AccessType.VIEW],
            },
        ],
    }, {    // Flight Plan Configuration - OE NOTAM
        routes: [FLIGHT_PLAN_CONFIG_PATHS.oeNotam],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH_READ,
                access: [AccessType.VIEW],
            },
        ],
    }, {    // Dep & Arr - Crew Feedback, "Create" means to create new tag in "Tag" page
        routes: [FLIGHT_PLAN_CONFIG_PATHS.feedback],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH_READ,
                access: [AccessType.VIEW],
            },
        ],
    }, {    // Data Configuration  - Car report topic
        routes: [DATA_CONFIGURATION_PATHS.carReportTopic],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.UPDATE],
            },
        ],
    }, {    // Data Configuration  - Car report email
        routes: [DATA_CONFIGURATION_PATHS.carReportEmail],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
        ],
    }, {    // Data Configuration  - Sid/Star
        routes: [FLIGHT_PLAN_CONFIG_PATHS.sidStar],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
        ],
    }, {    // Closed Flight- Closed Flight
        routes: [CLOSE_FLIGHT_PATHS.closeFlight, CLOSE_FLIGHT_PATHS.closeFlightDetail],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW],
            },
            {
                userGroupID: UserGroupIDType.CX_AUDIT_READ,
                access: [AccessType.VIEW],
            },
        ],
    }, {    // SIM - SIM OFP
        routes: [SIM_PATHS.landing, SIM_PATHS.releaseSimOFP],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_SIM,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
        ],
    }, {    // SIM - Template Configuration
        routes: [SIM_PATHS.simTemplateConfiguration, SIM_PATHS.simTemplateDetail],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_SIM,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.DELETE],
            },
        ],
    }, {    // HKE Flight Plan Configuration- Dashboard
        routes: [UO_PATHS.flightPlan],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.UO_DISPATCH_READ,
                access: [AccessType.VIEW],
            },
        ],
    }, {    // Closed Flight- Closed Flight
        routes: [UO_PATHS.closeFlight, UO_PATHS.closeFlightDetail],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.UO_DISPATCH_READ,
                access: [AccessType.VIEW],
            }
        ],
    }, {
        routes: [FLIGHT_PLAN_CONFIG_PATHS.hkeSnn],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH_READ,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.UO_DISPATCH_READ,
                access: [AccessType.VIEW],
            },
        ],
    }, {    // HKE Flight Plan Configuration - Fuel Advisory
        routes: [UO_PATHS.fuelAdv],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.UO_DISPATCH_READ,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
        ],
    }, {    // HKE Flight Plan Configuration - Defects
        routes: [UO_PATHS.defect],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.UO_DISPATCH_READ,
                access: [AccessType.VIEW],
            },
        ],
    }, {    // HKE Flight Plan Configuration - Crewlist
        routes: [UO_PATHS.crewlist],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.UO_DISPATCH_READ,
                access: [AccessType.VIEW],
            },
        ],
    }, {    // HKE Flight Plan Configuration - NSU/CO NOTAM
        routes: [UO_PATHS.nsuNotam],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.UO_DISPATCH_READ,
                access: [AccessType.VIEW],
            },
        ],
    }, {    // HKE Flight Plan Configuration - OE NOTAM
        routes: [UO_PATHS.oeNotam],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.UO_DISPATCH_READ,
                access: [AccessType.VIEW],
            },
        ],
    }, {    // HKE Flight Plan Configuration - Appendix Rules
        routes: [UO_PATHS.appendixRule],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.CX_DISPATCH_READ,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.UO_DISPATCH_READ,
                access: [AccessType.VIEW],
            },
        ],
    },
    /* HKE Data Configuration (ignore)
    {    // HKE Data Configuration - Car Report Topic
        routes: [DATA_CONFIGURATION_PATHS.uoCarReportTopic],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
        ],
    }, {    // HKE Data Configuration - Car Report Email
        routes: [DATA_CONFIGURATION_PATHS.uoCarReportEmail],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
        ],
    }, {    // HKE Data Configuration - Sid Star
        routes: [DATA_CONFIGURATION_PATHS.uoSidStar],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
        ],
    }, 
    */
    {    // HKE Internal Support - Data-cf_ccs
        routes: [Internal_Support_PATHS.dataCfCcs],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.SUPPORT,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
        ],
    },
    {    // Hidden - Dynamic Data
        routes: [FLIGHT_PLAN_CONFIG_PATHS.data, FLIGHT_PLAN_CONFIG_PATHS.dataDetail],
        roleAccessList: [
            {
                userGroupID: UserGroupIDType.SUPER_ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
            {
                userGroupID: UserGroupIDType.ADMIN,
                access: [AccessType.VIEW, AccessType.CREATE, AccessType.UPDATE, AccessType.DELETE],
            },
        ],
    },
];

// routes that are not included in the role access mapping (disable role access checking)
const routesExcludeFromRoleAccessMapping: string[] = [
    DATA_CONFIGURATION_PATHS.uoCarReportTopic,
    DATA_CONFIGURATION_PATHS.uoCarReportEmail,
    DATA_CONFIGURATION_PATHS.uoSidStar,
];

export { ROLE_ACCESS_MAPPING, routesExcludeFromRoleAccessMapping, AccessType, UserGroupIDType, }